@import "grid.abstract";

#{$row} {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-flow: row wrap;
  margin-right: space(-1);
  margin-left: space(-1);

  &#{$row}--reverse {
    flex-direction: row-reverse;
  }

  &#{$row}--reverse-m {
    @include breakpoint(m) {
      flex-direction: row-reverse;
    }
  }

  &#{$row}--spread {
    justify-content: space-between;
  }
}

#{$col} {
  box-sizing: border-box;
  flex: 0 0 auto;
  flex-grow: 1;
  max-width: 100%;
  padding-right: space();
  padding-left: space();
  width: 100%;

  @include createColumns();
  @include createColumns(s);
  @include createColumns(m);
  @include createColumns(l);
}
