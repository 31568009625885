@import "common.abstract";

.display-inline {
  display: inline !important;
}

.display-inline-block {
  display: inline-block !important;
}

.display-flex {
  display: flex !important;
}

.display-table {
  display: table !important;
}

.display-block,
.shown {
  display: block !important;
}

.hidden {
  display: none !important;
}

.shown-xs,
.shown-s,
.shown-m,
.shown-l {
  display: none !important;
}

@include breakpoint(xs, s) {
  .hidden-xs {
    display: none !important;
  }

  .shown-xs {
    display: block !important;
  }
}

@include breakpoint(s, m) {
  .hidden-s {
    display: none !important;
  }

  .shown-s {
    display: block !important;
  }
}

@include breakpoint(m, l) {
  .hidden-m {
    display: none !important;
  }

  .shown-m {
    display: block !important;
  }
}

@include breakpoint(l) {
  .hidden-l {
    display: none !important;
  }

  .shown-l {
    display: block !important;
  }
}

.scroll-x {
  overflow-x: auto !important;
}

.scroll-y {
  overflow-y: auto !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-left {
  text-align: left !important;
}

.text-align-right {
  text-align: right !important;
}

.text-bold {
  font-weight: 700 !important;
}

.text-italic {
  font-style: italic !important;
}

.text-underline {
  text-decoration: underline !important;
}

.margin-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.margin-0 {
  margin: 0 !important;
}

.margin-0-5 {
  margin: space(0.5) !important;
}

.margin-1 {
  margin: space() !important;
}

.margin-2 {
  margin: space(2) !important;
}

.margin-3 {
  margin: space(3) !important;
}

.margin-4 {
  margin: space();
}

.margin-top-0 {
  margin-top: 0 !important;
}

.margin-top-0-5 {
  margin-top: space(0.5) !important;
}

.margin-top-1 {
  margin-top: space() !important;
}

.margin-top-2 {
  margin-top: space(2) !important;
}

.margin-top-3 {
  margin-top: space(3) !important;
}

.margin-top-4 {
  margin-top: space(4) !important;
}

.margin-right-0 {
  margin-right: 0 !important;
}

.margin-right-0-5 {
  margin-right: space(0.5) !important;
}

.margin-right-1 {
  margin-right: space() !important;
}

.margin-right-2 {
  margin-right: space(2) !important;
}

.margin-right-3 {
  margin-right: space(3) !important;
}

.margin-right-4 {
  margin-right: space(4) !important;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-bottom-0-5 {
  margin-bottom: space(0.5) !important;
}

.margin-bottom-1 {
  margin-bottom: space() !important;
}

.margin-bottom-2 {
  margin-bottom: space(2) !important;
}

.margin-bottom-3 {
  margin-bottom: space(3) !important;
}

.margin-bottom-4 {
  margin-bottom: space(4) !important;
}

.margin-left-0 {
  margin-left: 0 !important;
}

.margin-left-0-5 {
  margin-left: space(0.5) !important;
}

.margin-left-1 {
  margin-left: space() !important;
}

.margin-left-2 {
  margin-left: space(2) !important;
}

.margin-left-3 {
  margin-left: space(3) !important;
}

.margin-left-4 {
  margin-left: space(4) !important;
}

.padding-0 {
  padding: 0 !important;
}

.padding-0-5 {
  padding: space(0.5) !important;
}

.padding-1 {
  padding: space() !important;
}

.padding-2 {
  padding: space(2) !important;
}

.padding-3 {
  padding: space(3) !important;
}

.padding-4 {
  padding: space(4) !important;
}

.padding-top-0 {
  padding-top: 0 !important;
}

.padding-top-0-5 {
  padding-top: space(0.5) !important;
}

.padding-top-1 {
  padding-top: space() !important;
}

.padding-top-2 {
  padding-top: space(2) !important;
}

.padding-top-3 {
  padding-top: space(3) !important;
}

.padding-top-4 {
  padding-top: space(4) !important;
}

.padding-right-0 {
  padding-right: 0 !important;
}

.padding-right-0-5 {
  padding-right: space(0.5) !important;
}

.padding-right-1 {
  padding-right: space() !important;
}

.padding-right-2 {
  padding-right: space(2) !important;
}

.padding-right-3 {
  padding-right: space(3) !important;
}

.padding-right-4 {
  padding-right: space(4) !important;
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.padding-bottom-0-5 {
  padding-bottom: space(0.5) !important;
}

.padding-bottom-1 {
  padding-bottom: space() !important;
}

.padding-bottom-2 {
  padding-bottom: space(2) !important;
}

.padding-bottom-3 {
  padding-bottom: space(3) !important;
}

.padding-bottom-4 {
  padding-bottom: space(4) !important;
}

.padding-left-0 {
  padding-left: 0 !important;
}

.padding-left-0-5 {
  padding-left: space(0.5) !important;
}

.padding-left-1 {
  padding-left: space() !important;
}

.padding-left-2 {
  padding-left: space(2) !important;
}

.padding-left-3 {
  padding-left: space(3) !important;
}

.padding-left-4 {
  padding-left: space(4) !important;
}
