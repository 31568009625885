@import "media.abstract";

#{$ratio} {
  position: relative;

  &::before {
    display: block;
    content: "";
    width: 100%;
  }

  &#{$ratio}--1-1::before {
    padding-top: 100%;
  }

  &#{$ratio}--4-3::before {
    padding-top: 3 / 4 * 100%;
  }

  &#{$ratio}--16-9::before {
    padding-top: 9 / 16 * 100%;
  }

  > * {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
