.app-layout--black-and-white .code-example {
  filter: grayscale(1);
}

.kpn-style-logo {
  @include shadow(3);
  border-radius: 20px;
  width: 200px;

  @include breakpoint(s) {
    width: 240px;
  }
}

.stylish-cat {
  max-width: 280px;
}

h1,
h2,
h3 {
  $header-height: $top-bar-height + $title-bar-height + space(3);
  &:not([class])::before {
    display: block;
    height: $header-height;
    margin-top: -$header-height;
    content: "";
  }
}
