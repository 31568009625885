$ui-font-family: "kpn-ui-icons" !default;
$ui-font-size-s: 18px !default;
$ui-font-size-m: 24px !default;
$ui-font-size-l: 36px !default;
$ui-font: #{$ui-font-size-m}/1 $ui-font-family;

$ui-30-seconds-backwords: "\e902";
$ui-30-seconds-forwards: "\e903";
$ui-account-diap: "\e904";
$ui-account: "\e905";
$ui-add: "\e906";
$ui-arrow-down: "\e907";
$ui-arrow-left: "\e908";
$ui-arrow-right: "\e909";
$ui-arrow-up: "\e90a";
$ui-arrow-upleft: "\e90b";
$ui-assemble: "\e90c";
$ui-asterisk: "\e90d";
$ui-audio: "\e90e";
$ui-backspace: "\e90f";
$ui-backward: "\e910";
$ui-bookmark: "\e911";
$ui-bulb: "\e912";
$ui-business-diap: "\e913";
$ui-business: "\e914";
$ui-calendar: "\e915";
$ui-call-ending: "\e916";
$ui-call-incoming: "\e917";
$ui-call-outgoing: "\e918";
$ui-camera: "\e919";
$ui-car: "\e91a";
$ui-caret-down: "\e91b";
$ui-caret-up: "\e91c";
$ui-chat-diap: "\e91d";
$ui-chat: "\e91e";
$ui-check: "\e91f";
$ui-chevron-down: "\e920";
$ui-chevron-left: "\e921";
$ui-chevron-right: "\e922";
$ui-chevron-up: "\e923";
$ui-chromecast: "\e924";
$ui-circle-diap: "\e925";
$ui-circle: "\e926";
$ui-clock: "\e927";
$ui-close: "\e928";
$ui-cloud: "\e929";
$ui-cross: "\e92a";
$ui-csv-file: "\e92b";
$ui-delete-all: "\e92c";
$ui-delete: "\e92d";
$ui-dialpad: "\e92e";
$ui-diamond-diap: "\e92f";
$ui-diamond: "\e930";
$ui-doc-file: "\e931";
$ui-document: "\e932";
$ui-download: "\e933";
$ui-dropdown: "\e934";
$ui-edit: "\e935";
$ui-emoticon-happy: "\e936";
$ui-emoticon-neutral: "\e937";
$ui-emoticon-sad: "\e938";
$ui-envelop: "\e939";
$ui-error: "\e93a";
$ui-euro: "\e93b";
$ui-external-link: "\e93c";
$ui-facebook: "\e93d";
$ui-female: "\e93e";
$ui-filter: "\e93f";
$ui-fingerprint: "\e940";
$ui-fire: "\e941";
$ui-flag: "\e942";
$ui-folder: "\e943";
$ui-forward: "\e944";
$ui-fullscreen-enter: "\e945";
$ui-fullscreen-exit: "\e946";
$ui-graphview: "\e947";
$ui-gridview: "\e948";
$ui-hash: "\e949";
$ui-heart: "\e94a";
$ui-help: "\e94b";
$ui-hide: "\e94c";
$ui-history: "\e94d";
$ui-home-diap: "\e94e";
$ui-home: "\e94f";
$ui-infinite: "\e950";
$ui-info-diap: "\e951";
$ui-info: "\e952";
$ui-internet: "\e953";
$ui-kijkwijzer-6plus: "\e954";
$ui-kijkwijzer-9plus: "\e955";
$ui-kijkwijzer-12plus: "\e956";
$ui-kijkwijzer-16plus: "\e957";
$ui-kijkwijzer-alle-leeftijden: "\e958";
$ui-kijkwijzer-angst: "\e959";
$ui-kijkwijzer-discriminatie: "\e95a";
$ui-kijkwijzer-drugs: "\e95b";
$ui-kijkwijzer-geweld: "\e95c";
$ui-kijkwijzer-sex: "\e95d";
$ui-kijkwijzer-taalgebruik: "\e95e";
$ui-laptop: "\e95f";
$ui-library: "\e960";
$ui-link: "\e961";
$ui-list: "\e962";
$ui-listview: "\e963";
$ui-locate: "\e964";
$ui-lock-closed: "\e965";
$ui-lock-open: "\e966";
$ui-logout: "\e967";
$ui-mail: "\e968";
$ui-male: "\e969";
$ui-manual: "\e96a";
$ui-map-view: "\e96b";
$ui-marker-diap: "\e96c";
$ui-marker: "\e96d";
$ui-menu: "\e96e";
$ui-message: "\e96f";
$ui-microphone-off: "\e970";
$ui-microphone-on: "\e971";
$ui-minus: "\e972";
$ui-mobile: "\e973";
$ui-more: "\e974";
$ui-movie: "\e975";
$ui-multiple: "\e976";
$ui-network-diap: "\e977";
$ui-network: "\e978";
$ui-notifications-off: "\e979";
$ui-notifications-on: "\e97a";
$ui-notifications: "\e97b";
$ui-pause: "\e97c";
$ui-payment: "\e97d";
$ui-pdf-file: "\e97e";
$ui-person: "\e97f";
$ui-phone-ringing: "\e980";
$ui-phone: "\e981";
$ui-photo: "\e982";
$ui-play-disabled: "\e983";
$ui-play: "\e984";
$ui-playback: "\e985";
$ui-plus: "\e986";
$ui-plusminus: "\e987";
$ui-power: "\e988";
$ui-preferences: "\e989";
$ui-present: "\e98a";
$ui-products-diap: "\e98b";
$ui-products: "\e98c";
$ui-record-all: "\e98d";
$ui-record-disabled: "\e98e";
$ui-record: "\e98f";
$ui-recordings: "\e990";
$ui-redo: "\e991";
$ui-refresh: "\e992";
$ui-remove-diap: "\e993";
$ui-remove: "\e994";
$ui-renew: "\e995";
$ui-rewind: "\e996";
$ui-robot: "\e997";
$ui-scroll-to-top: "\e998";
$ui-search: "\e999";
$ui-secure: "\e99a";
$ui-select: "\e99b";
$ui-send: "\e99c";
$ui-settings: "\e99d";
$ui-share: "\e99e";
$ui-shift: "\e99f";
$ui-shop: "\e9a0";
$ui-shoppingbasket: "\e9a1";
$ui-show: "\e9a2";
$ui-simcard: "\e9a3";
$ui-star-diap: "\e9a4";
$ui-star: "\e9a5";
$ui-stop: "\e9a6";
$ui-tablet: "\e9a7";
$ui-text: "\e9a8";
$ui-ticket-diap: "\e9a9";
$ui-ticket: "\e9aa";
$ui-tv-guide: "\e9ab";
$ui-tv: "\e9ac";
$ui-twitter: "\e9ad";
$ui-undo: "\e9ae";
$ui-upload: "\e9af";
$ui-video: "\e9b0";
$ui-voicemail: "\e9b1";
$ui-volume-high: "\e9b2";
$ui-volume-low: "\e9b3";
$ui-volume-medium: "\e9b4";
$ui-volume-off: "\e9b5";
$ui-warning: "\e9b6";
$ui-watch: "\e9b7";
$ui-watchlist: "\e9b8";
$ui-whatsapp: "\e9b9";
$ui-wifi-disabled: "\e9ba";
$ui-wifi: "\e9bb";
$ui-zip-file: "\e9bc";
