@import "backdrop.abstract";

#{$backdrop} {
  background-color: rgba(color(grey, dark), 0.5);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;

  &#{$backdrop}--active {
    display: block;
  }
}
