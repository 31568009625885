@import "footer.abstract";

#{$footer} {
  background-color: color(grey, dove);
  color: color(grey, white);
  font-size: $font-size-s;

  #{$footer}__list {
    @include reset-dl;
    margin-bottom: space(3);

    dt {
      font-weight: 700;
    }
  }

  #{$footer}__body {
    @include container(x);
    box-sizing: border-box;
    padding-top: space(4);
    padding-bottom: space();
    margin-left: auto;
    margin-right: auto;
    max-width: screen(l);

    + #{$footer}__body {
      border-top: 1px solid color(grey, white);
      position: relative;
    }
  }

  #{$footer}__inline-list {
    @include reset-ul;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: space(3);

    &#{$footer}__inline-list--separated {
      li:not(:last-child)::after {
        color: color(grey, white);
        content: " |";
        display: inline-block;
        margin-left: space(2);
      }
    }

    li {
      margin-left: space();
      margin-right: space();
    }
  }

  #{$footer}__link {
    color: color(grey, white);
    cursor: pointer;
    display: inline-block;
    padding-bottom: space();
    padding-top: space();

    &:hover {
      text-decoration: underline;
    }
  }

  #{$footer}__logo {
    display: block;

    @include breakpoint(xs, m) {
      margin-bottom: space(3);
    }

    @include breakpoint(m) {
      bottom: space(4);
      left: space(3);
      position: absolute;
    }

    img {
      margin: 0 auto;
      display: block;
      width: 80px;
    }
  }
}
