$row: selector(row);
$col: selector(col);

@mixin createColumns($screenSize: "xs") {
  $respond: $screenSize + "-";

  @if ($screenSize == "xs") {
    $respond: "";
  }

  @include breakpoint($screenSize) {
    // Auto grid columns
    &#{$col}--#{$screenSize} {
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100%;
    }

    // Portion grid columns
    &#{$col}--#{$respond}1 {
      flex-basis: 8.33%;
      max-width: 8.33%;
    }

    &#{$col}--#{$respond}2 {
      flex-basis: 16.66%;
      max-width: 16.66%;
    }

    &#{$col}--#{$respond}3 {
      flex-basis: 25%;
      max-width: 25%;
    }

    &#{$col}--#{$respond}4 {
      flex-basis: 33.33%;
      max-width: 33.33%;
    }

    &#{$col}--#{$respond}5 {
      flex-basis: 41.66%;
      max-width: 41.66%;
    }

    &#{$col}--#{$respond}6 {
      flex-basis: 50%;
      max-width: 50%;
    }

    &#{$col}--#{$respond}7 {
      flex-basis: 58.33%;
      max-width: 58.33%;
    }

    &#{$col}--#{$respond}8 {
      flex-basis: 66.66%;
      max-width: 66.66%;
    }

    &#{$col}--#{$respond}9 {
      flex-basis: 75%;
      max-width: 75%;
    }

    &#{$col}--#{$respond}10 {
      flex-basis: 83.33%;
      max-width: 83.33%;
    }

    &#{$col}--#{$respond}11 {
      flex-basis: 91.66%;
      max-width: 91.66%;
    }

    &#{$col}--#{$respond}12 {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
}
