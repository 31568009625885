@import "app-layout.abstract";

#{$app-body} {
  background-color: color(grey, subtle);
  margin: 0;
}

#{$app-layout} {
  $fixed-index: 100;
  overflow-x: hidden;

  &#{$app-layout}--has-side-bar {
    @include breakpoint(m) {
      #{$app-layout}__main,
      #{$title-bar}__title {
        margin-left: $side-bar-width;
      }
    }
  }

  &#{$app-layout}--active-side-bar {
    @include breakpoint(xs, m) {
      #{$side-bar} {
        transform: translateX(100%);
      }
    }
  }

  #{$app-layout}__top-bar {
    left: 0;
    position: fixed;
    width: 100%;
    z-index: $fixed-index + 1;
  }

  #{$app-layout}__side-bar {
    // Why two side-bar selectors?
    // .side-bar can be chained with #{$app-layout}__side-bar or .side-bar can be a child of #{$app-layout}__side-bar
    &#{$side-bar},
    #{$side-bar} {
      overflow-y: auto;
      position: fixed;

      @include breakpoint(xs, m) {
        height: 100vh;
        left: -100%;
        transition: transform 0.5s ease-in-out;
        z-index: $fixed-index + 1;
        width: 100%;
      }

      @include breakpoint(m) {
        height: calc(100vh - #{$top-bar-height + $title-bar-height});
        left: 0;
        top: $top-bar-height + $title-bar-height;

        // Side bar detection to move Main and Title. If this will not work there is the class .app-layout--has-side-bar
        + #{$app-layout}__main {
          margin-left: $side-bar-width;

          #{$title-bar}__title {
            margin-left: $side-bar-width;
          }
        }
      }
    }
  }

  #{$app-layout}__main {
    display: block; // Support for IE11 when class is used on <main> element

    @include breakpoint(xs, m) {
      padding-top: $top-bar-height;
      transition: transform 0.5s;
    }

    @include breakpoint(m) {
      padding-top: $top-bar-height + $title-bar-height;
    }
  }

  #{$app-layout}__title-bar {
    @include breakpoint(m) {
      left: 0;
      position: fixed;
      top: $top-bar-height;
      width: 100%;
      z-index: $fixed-index;
    }
  }
}
